@import "./_variables";

.header-hook {
  width: 100%;
  min-height: 40vh;
  color: $font-color;

  .content-box {
    width: 80%;
    height: 100%;
    font-size: clamp(2rem, 5.5vw, 3.5rem);
  }

  .join-us {
    margin-top: 1rem;
    font-size: clamp(2rem, 4vw, 3rem);
    border: 3px solid black;
    border-radius: 10px;
    background-color: $logo-yellow;
    transition: 1s;
    transform-origin: bottom;
    margin-bottom: 10px;
  }

  .join-us:hover {
    transform: scale(1.07);
  }
}

.about-us {
  // fee531
  color: #ffe8e4;

  background: rgb(147, 34, 19);
  background: -moz-linear-gradient(
    123deg,
    rgba(147, 34, 19, 1) 0%,
    rgba(185, 44, 25, 1) 44%,
    rgba(210, 49, 27, 1) 60%,
    rgba(255, 59, 33, 1) 100%
  );
  background: -webkit-linear-gradient(
    123deg,
    rgba(147, 34, 19, 1) 0%,
    rgba(185, 44, 25, 1) 44%,
    rgba(210, 49, 27, 1) 60%,
    rgba(255, 59, 33, 1) 100%
  );
  background: linear-gradient(
    123deg,
    rgba(147, 34, 19, 1) 0%,
    rgba(185, 44, 25, 1) 44%,
    rgba(210, 49, 27, 1) 60%,
    rgba(255, 59, 33, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#932213",endColorstr="#ff3b21",GradientType=1);

  height: 60vh;
  display: table;

  .image-box {
    display: table-cell;
    height: 100%;
    min-width: 40%;
    vertical-align: middle;

    img {
      height: 80%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .text-box {
    display: table-cell;
    width: auto;
    height: 100%;
    vertical-align: middle;
    text-align: left;
    padding: 0 1.5rem;

    h1 {
      font-size: clamp(2rem, 4vw, 3.5rem);
    }

    p {
      font-size: clamp(1.4rem, 2vw, 2.8rem);
      text-align: justify;
    }
  }

  @media (max-width: 1250px) {
    display: flex;
    flex-direction: column;
    height: fit-content;

    p {
      padding-right: 1.5rem;
    }

    .image-box {
      visibility: hidden;
      width: 0;
      min-width: unset;
    }

    .text-box {
      width: 100%;
      text-align: center;
    }
  }
}
