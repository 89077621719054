@import "./_variables";

.members {
  // padding: 0 10vw;

  width: 100%;

  .border {
    border: 3px solid black;
  }

  h1 {
    font-size: clamp(2.6rem, 4vw, 3.7rem);
  }

  .members-text {
    color: black;
    width: 100%;
    margin-bottom: 3vh;

    .members-page-title {
      padding-top: 2%;
    }

    .members-page-text {
      text-align: justify;
      height: 50%;
      font-size: clamp(1rem, 4vw, 2.2rem);
      padding: 0 10vw;
    }
  }
}
