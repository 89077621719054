$primary-bg: white;
$navbar-bg: #000000;
$navbar-fc: white;
$secondary-bg: #a66056;

$font-color: black;

$logo-yellow: #fcb813;
$logo-red: #bf2e19;

$navbar-length: 9vh;
$footer-length: 8vh;
$content-height: calc(100vh - ($navbar-length + $footer-length));