@import "./_variables.scss";

.error {
  width: 100vw;
  height: 100vh;
  background: rgb(163, 21, 0);
  background: -moz-radial-gradient(circle, rgba(163, 21, 0, 1) 0%, rgba(163, 34, 3, 1) 33%, rgba(166, 123, 20, 1) 96%);
  background: -webkit-radial-gradient(circle, rgba(163, 21, 0, 1) 0%, rgba(163, 34, 3, 1) 33%, rgba(166, 123, 20, 1) 96%);
  background: radial-gradient(circle, rgba(163, 21, 0, 1) 0%, rgba(163, 34, 3, 1) 33%, rgba(166, 123, 20, 1) 96%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a31500",endColorstr="#a67b14",GradientType=1);
  position: absolute;

  vertical-align: middle;
  color: white;

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    font-size: 1.25rem;

    a {
      color: rgb(54, 0, 54);
    }
  }

  h1 {
    font-size: 6rem;
    border-bottom: 3px solid white;

    span {
      animation: glow 3s linear infinite;
    }
  }
  @keyframes glow {
    0% {
      color: white;
    }
  
    50% {
      color: $logo-yellow;
    }
  
    100% {
      color: white;
    }
  }
}

.shadow-error {
  $shadow-red: #d70202;
  $shadow-red-mid: #c22a4b;
  $shadow-grey: #808eae;
  width: 100vw;
  height: 100vh;
  color: $shadow-grey;
  background: rgb(66, 70, 158);
  background: -moz-linear-gradient(
    22deg,
    rgba(66, 70, 158, 1) 0%,
    rgba(152, 95, 209, 1) 20%,
    rgba(71, 42, 101, 1) 40%,
    rgba(42, 29, 55, 1) 60%,
    rgba(40, 40, 52, 1) 80%,
    rgba(11, 10, 15, 1) 100%
  );
  background: -webkit-linear-gradient(
    22deg,
    rgba(66, 70, 158, 1) 0%,
    rgba(152, 95, 209, 1) 20%,
    rgba(71, 42, 101, 1) 40%,
    rgba(42, 29, 55, 1) 60%,
    rgba(40, 40, 52, 1) 80%,
    rgba(11, 10, 15, 1) 100%
  );
  background: linear-gradient(
    22deg,
    rgba(66, 70, 158, 1) 0%,
    rgba(152, 95, 209, 1) 20%,
    rgba(71, 42, 101, 1) 40%,
    rgba(42, 29, 55, 1) 60%,
    rgba(40, 40, 52, 1) 80%,
    rgba(11, 10, 15, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#42469e",endColorstr="#0b0a0f",GradientType=1);

  a {
    color: inherit;
    text-decoration: none;
    animation: shadow-glow 4s linear 1 forwards;
  }

  @keyframes shadow-glow {
    0% {
      color: $shadow-grey;
    }
  
    50% {
      color: $shadow-red-mid;
    }
  
    100% {
      color: $shadow-red;
    }
  }
}
