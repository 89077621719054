@import "./_variables";

#navBar {
  background-color: $navbar-bg;
  font-size: clamp(1.5rem, 1vw, 7rem);
  height: $navbar-length;
  color: $navbar-fc;
  width: 100vw;

  nav {
    margin-left: auto;
    padding-right: 5%;
  }
  
  button {
    width: 50px;
    height: 50px;
    padding: 0;
    margin-right: 3%;
    border: none;
    visibility: collapse;
    background: none;
  }
  
  @media (max-width: 900px) {
    nav {
      display: none;
      visibility: collapse;
      width: 0;
      margin-left: 0;
      padding-right: 0;
    }
  
    button {
      visibility: visible;
      margin-left: auto;
    }
  }
  
  nav>a {
    padding: 0 1rem;
    color: $navbar-fc;
    text-decoration: none;
  }
  
  nav>a:hover {
    color: rgb(139, 139, 139);
  }
  
  .nav-brand {
    color: $navbar-fc;
    font-size: 1.2em;
  }
  
  .nav-brand>img {
    padding: 0.5rem 0.8rem;
    height: calc($navbar-length);
    justify-content: center;
    align-items: center;
  }
  
  #faIcon {
    color: $navbar-fc;
    height: 85%;
  }
}

.change-to-x {
  animation: shrink .2s 1 normal, rotate .3s .2s 1 normal forwards;
}

@keyframes shrink {
  from {
    transform: scaleY(1)
  }
  to {
    transform: scaleY(.11);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
   to {
    transform: rotate(45deg);
   }
}