@import "./_variables";

.footer {
  background-color: $navbar-bg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position:relative;
  bottom: 0;
  font-size: clamp(1.2rem, 1vw, 7rem);
  padding: 0 0.5rem;
  color: $navbar-fc;
  height: $footer-length;
  width: 100% !important;

  .box {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $footer-length;
  }
  
  .vtechclub {
    padding: 10px;
  }
  
  .vtechclub>img {
    width: 50px;
    padding-right: .5rem;
  }
  
  .contact{
      font-size: clamp(10px, 2vh, 23px);
      white-space: nowrap;
      padding-right: 12px;
      max-height: 100%;
  }
  
  .social {
    padding: 0 10px;
    color: $navbar-fc;
    font-size: clamp(18px, 2.5vh, 30px);
  }
  
  .fa-bounce-hover:hover {
    -webkit-animation: fa-bounce 1s 1 linear;
    -moz-animation: fa-bounce 1s 1 linear;
    -o-animation: fa-bounce 1s 1 linear;
    animation: fa-bounce 1.25s 1 linear;
  }
}