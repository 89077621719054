.member-accordian {
  .accordian-button {
    width: 100%;
    border: none;
    border-bottom: 3px solid rgb(96, 96, 96);
    background: none;

    h2 {
      padding-left: 30px;
      padding-top: 10px;
      font-size: clamp(2.2rem, 3.8vw, 2.8rem);
    }

    .accordian-icon {
      position: absolute;
      right: 20px;
      font-size: 30px;
    }

    .accordian-icon.canPlus {
      transition: 0.5s;
    }
  }

  .grid-item {
    justify-self: center;
  }

  .accordian-content {
    width: 100%;
    overflow: hidden;
    // width: clamp(80vw, 1500px, 98vw);
    justify-self: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    grid-gap: 10px;
  }

  .accordian-expand {
    animation: expand 1s 1 forwards;
  }

  .accordian-contract {
    animation: contract 1s 1 forwards;
  }

  @keyframes expand {
    0% {
      max-height: 0vh;
    }

    99% {
      max-height: 100vh;
    }

    100% {
      max-height: unset;
    }
  }

  @keyframes contract {
    0% {
      max-height: 100vh;
    }

    100% {
      max-height: 0vh;
    }
  }
}
