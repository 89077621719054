@import "./_variables";

$ribbon-height: 15vh;
$location-height: 10vh;

.join-us-div {
  height: $content-height;

  .icon {
    height: 30px;
  }

  .join-us-ribbon {
    min-height: $ribbon-height;

    .join-us-ribbon-text {
      text-align: justify;
      align-items: center;
    }
  }

  .location {
    height: $location-height;
    color: white;

    background: rgb(74, 88, 115);
    background: -moz-linear-gradient(
      123deg,
      rgba(74, 88, 115, 1) 0%,
      rgba(76, 87, 108, 1) 20%,
      rgba(69, 76, 90, 1) 49%,
      rgba(65, 71, 83, 1) 100%
    );
    background: -webkit-linear-gradient(
      123deg,
      rgba(74, 88, 115, 1) 0%,
      rgba(76, 87, 108, 1) 20%,
      rgba(69, 76, 90, 1) 49%,
      rgba(65, 71, 83, 1) 100%
    );
    background: linear-gradient(
      123deg,
      rgba(74, 88, 115, 1) 0%,
      rgba(76, 87, 108, 1) 20%,
      rgba(69, 76, 90, 1) 49%,
      rgba(65, 71, 83, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4a5873",endColorstr="#414753",GradientType=1);

    h3 {
      font-size: clamp(10px, 3.2vh, 100px);
    }

    .where {
      flex: 1;
      width: 50vw;
      min-height: $location-height;
    }
    .when {
      flex: 1;
      width: 50vw;
      min-height: $location-height;
    }
    .logo {
      height: $location-height;

      img {
        height: $location-height + 5vh;
      }
    }
  }

  .join-us-links {
    height: $content-height - ($location-height + $ribbon-height);

    background: rgb(233, 193, 57);
    background: -moz-linear-gradient(
      123deg,
      rgba(233, 193, 57, 1) 0%,
      rgba(255, 201, 19, 1) 20%,
      rgba(252, 184, 19, 1) 49%,
      rgba(216, 170, 15, 1) 100%
    );
    background: -webkit-linear-gradient(
      123deg,
      rgba(233, 193, 57, 1) 0%,
      rgba(255, 201, 19, 1) 20%,
      rgba(252, 184, 19, 1) 49%,
      rgba(216, 170, 15, 1) 100%
    );
    background: linear-gradient(
      123deg,
      rgba(233, 193, 57, 1) 0%,
      rgba(255, 201, 19, 1) 20%,
      rgba(252, 184, 19, 1) 49%,
      rgba(216, 170, 15, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e9c139",endColorstr="#d8aa0f",GradientType=1);

    .flex-between {
      width: 100%;
    }

    .engage {
      background-color: #af2818;
    }
    .discord {
      background-color: #2e358c;
    }

    .social-card {
      width: 20vw;
      height: 40vh;
      margin-top: 10px;

      color: white;

      border: 4px solid black;
      border-radius: 3rem;

      .discord {
        img {
          padding-bottom: 100px;
        }
      }

      .engage {
        background-color: #af2818;
      }

      h3 {
        margin-top: 30px;
        margin-bottom: -30px;
      }
    }

    img {
      width: clamp(80px, 8vw, 1000px);
      filter: drop-shadow(0 0 0.2rem black);
    }

    h1 {
      padding-top: 2rem;
    }

    a {
      text-decoration: none;
    }
  }

  @keyframes cyclone {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-1440deg);
    }
  }

  #engageImg {
    animation: cyclone 1.5s ease-in-out 0s 1;
  }

  .spin {
    animation: cyclone 1.5s ease-in-out 0s infinite !important;
  }

  @keyframes bounce {
    to {
      transform: translateY(-5px);
    }
  }

  #discordImg {
    animation: bounce 0.5s 0s 3;
    animation-timing-function: cubic-bezier(1, 4, 1, -4) !important;
  }

  .bounce {
    animation: bounce 0.5s 0s infinite !important;
    animation-timing-function: cubic-bezier(1, 4, 1, -4) !important;
  }

  @media (max-width: 1250px) {
    overflow: hidden;

    .location {
      height: 30vh;

      display: flex;
      flex-direction: column;
      align-items: center;
      .where,
      .when {
        width: 100%;
      }

      .logo {
        visibility: collapse;
      }
    }

    .join-us-links {
      height: calc(
        $content-height - ($location-height + $ribbon-height + 20vh)
      );

      // display: block;

      .flex-between {
        flex-direction: column;
      }

      .small-card {
        width: 95vw;
        height: 30vh;
        border-radius: 20px;
        background-color: white;
        border: 2px solid black;
        overflow: hidden;
      }

      .discord {
        border-bottom: 1px solid black !important;
      }

      .engage {
        border-top: 1px solid black !important;
      }

      .social-card {
        width: 100%;
        height: 100%;
        margin-top: 0;

        padding-left: 20px;

        flex-direction: row;
        justify-content: left;

        border: none;
        border-radius: 0;

        h3 {
          padding: 0;
          margin: 0 0 0 0;
          width: 100%;
          right: 0;
          position: absolute;

          @media (max-width: 450px) {
            position: relative;
            width: unset;
            margin-left: 15px;
          }
        }

        .discord {
          img {
            padding-bottom: 100px;
          }
        }
      }
    }
  }
}
