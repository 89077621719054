@import "./_variables";

.card {
  border: none;
  overflow: hidden;
  background-color: transparent;
  border-radius: 0;

  margin: 30px;

  width: 400px;

  // transition: transform 0.3s;

  // &:hover {
  //   transform: translateY(-5px);
  // }

  .card-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .card-content {
    padding: 20px;
    min-height: 20vh;
    text-align: left;

    .card-title {
      font-size: clamp(1.7rem, 4vw, 2rem);
      margin-bottom: 8px;
      color: black;
    }

    .card-subtitle {
      font-size: clamp(1rem, 3vw, 1.4rem);
      // color: #888;
      margin-bottom: 12px;
      color: black;
    }

    .card-text {
      font-size: clamp(1rem, 2vw, 1.2rem);
      // color: #444
      color: black;
    }
  }
}

.profile-picture {
  width: 100%;
  // height: 100%;
  object-fit: cover;
  // border-radius: 8px 8px 0 0;
}
