@import "./_variables";

#navMenu {
  right: 0;
  position: absolute;
  height: calc(100% - ($navbar-length + $footer-length));
  background-color: $logo-yellow;
  font-size: clamp(1.5rem, 1vw, 7rem);
  overflow-x: hidden;
  z-index: 1;
  transition: 1s;
  visibility: visible;
  background-color: $navbar-bg;

  nav > a {
    padding: 1rem 0;
    color: $navbar-fc;
    text-decoration: none;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
  }

  #closeMenu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  #closeMenu > button {
    margin: 10px;
    width: 50px;
    height: 50px;
    border: none;
    visibility: visible;
  }

  @media (min-width: 900px) {
    #navMenu {
      visibility: collapse;
    }

    #closeMenu > button {
      visibility: collapse;
    }
  }
}
