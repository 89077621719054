@import './_variables';

.popup {
  width: clamp(70vw, 40vw, 80vh);

  p {
    margin-top: 20px;
    text-align: left;
  }

  button {
    border: 3px solid black;
    border-radius: 5px;
    background: $logo-yellow;
    width: 4rem;
    height: 3rem;
  }
}

.popup::backdrop {
  background-color: black;
  opacity: .80;
}
