@import "./_variables";

@-ms-viewport {
  width: device-width;
  height: device-height;
}

body {
  overflow-x: hidden;
  background-color: $primary-bg;
  font-family: "Heebo", sans-serif;
  color: $font-color;
  text-align: center;
  width: 100%;
  height: 100%;
}

html,
body {
  overflow-x: hidden;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}

#root {
  position: absolute;
  width: 100vw;
}

.outlet {
  min-height: $content-height;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-top {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.flex-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.flex-center {
  @extend .flex;
  justify-content: center;
}

.flex-between {
  @extend .flex;
  justify-content: space-around;
}

.flex-right {
  @extend .flex;
  justify-content: right;
}

.flex-left {
  @extend .flex;
  justify-content: left;
}

.flex-left-col {
  @extend .flex-left;
  flex-direction: column;
}

.flex-center-col {
  @extend .flex-center;
  flex-direction: column;
}

.section {
  padding: 1vh 0;
}
